import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import {
  createRecord,
  getByCode,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";

import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";

const validationSchema = yup.object().shape({
  name: yup.string().required("Required *"),
  tsplCommands: yup.string().required("Required *"),
});

const entitySchema = {};

const PrintemplatesDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const params = useParams();
  const model = enums.models.printerTemplates;
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);

          formMethods.reset(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {},
        action = "Created";
      if (!values._id) {
        res = await createRecord(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        res = await updateRecord(model, values);
        action = "Updated";
      }
      setIsEditable(false);
      formMethods.reset(res);
      CustomEventEmitter.emit("alert_success", "Successfully " + action);
    } catch (err) {
      console.log(err);

      CustomEventEmitter.emit("alert_error", "Something went wrong");
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        setIsEditable={setIsEditable}
      />
    </FormProvider>
  );
};

export default PrintemplatesDetail;
